import {Scene} from 'phaser'
import store from "@/store";

import AssetsLoader from "@/game/utils/AssetsLoader"
import UI from "@/game/utils/UI"


//Assets
import work_place from '@/game/assets/WorkPlace/work_place.png'
import areiopagos_achievement from '@/game/assets/WorkPlace/achievements/areiopagos.png'
import headphones_achievement from '@/game/assets/WorkPlace/achievements/headphones.png'
import conditioner_achievement from '@/game/assets/WorkPlace/achievements/conditioner.png'
import capibara_achievement from '@/game/assets/WorkPlace/achievements/capibara.png'
import keyCard_achievement from '@/game/assets/WorkPlace/achievements/keyCard.png'
import comrades_achievement from '@/game/assets/WorkPlace/achievements/comrades.png'
import memolog_achievement from '@/game/assets/WorkPlace/achievements/memolog.png'
import prize from '@/game/assets/WorkPlace/prize.png'
import statistic from '@/game/assets/WorkPlace/statistic.png'
import logo from '@/game/assets/WorkPlace/logo.png'
import copied from '@/game/assets/WorkPlace/copied.png'
import router from "../../router";

export default  class WorkPlaceScene extends  Scene {
    constructor() {
        super({key: 'WorkPlaceScene'});
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
        this.achievements = null;
        this.areiopagos
        this.headphones
        this.conditioner
        this.capibara
        this.keyCard
        this.comrades
        this.prize
        this.memolog
    }

    preload() {
        this.assetsLoader.loadAssets()

        this.load.image('work_place', work_place)
        this.load.image('statistic', statistic)
        this.load.image('logo', logo)
        this.load.image('areiopagos_achievement', areiopagos_achievement)
        this.load.image('headphones_achievement', headphones_achievement)
        this.load.image('conditioner_achievement', conditioner_achievement)
        this.load.image('capibara_achievement', capibara_achievement)
        this.load.image('keyCard_achievement', keyCard_achievement)
        this.load.image('comrades_achievement', comrades_achievement)
        this.load.image('memolog_achievement', memolog_achievement)
        this.load.image('copied', copied)
        this.load.spritesheet('prize', prize, {frameWidth: 3396 / 3, frameHeight: 510});
    }

    create() {
        const hashedId = btoa(store.getters.getUserId);
        const shareLink = `${window.location.origin}${process.env.BASE_URL}share?screen=${hashedId}`;
        const shareText = 'Какой ты стажер? Посмотри, какие ачивки у меня сегодня👀 Залетай в игру и получай призы вместе со мной🚀';

        this.add.image(0, 0, 'work_place').setOrigin(0);

        this.areiopagos = this.add.image(this.scale.width - 203, 225, 'areiopagos_achievement').setOrigin(0).setAlpha(0);
        this.headphones = this.add.image(1342, 1, 'headphones_achievement').setOrigin(0).setAlpha(0);
        this.conditioner = this.add.image(1335, this.scale.height - 248, 'conditioner_achievement').setOrigin(0).setAlpha(0);
        this.capibara = this.add.image(870, 759, 'capibara_achievement').setAlpha(0);
        this.keyCard = this.add.image(298, 71, 'keyCard_achievement').setOrigin(0, 0).setAlpha(0);
        this.comrades = this.add.image(0, 312, 'comrades_achievement').setOrigin(0, 0).setAlpha(0);
        this.memolog =  this.add.image(1127, 637, 'memolog_achievement').setOrigin(0, 0).setAlpha(0);


        this.achievements = store.getters.getAchievements;
        this.achievements.forEach(achievement=>{
            if (this[achievement]) {
                this[achievement].setAlpha(1).setDepth(2)
            } else {
                console.log(achievement)
            }
        })

        const monitor = this.add.container(517, 172,)
        const textWidth = 420;
        const gap = 25;
        let dinamycY = 0;
        let hovers = [];

        let vk = null;
        let telegram = null;
        let share = null;

        const statisticScreen = this.add.image(textWidth + 13, dinamycY, 'statistic').setOrigin(0,0);

        if (store.getters.getNeedFinal === true && !store.getters.getPrize || store.getters.getPrize) {
            const titleText = 'ПОЗДРАВЛЯЕМ\nС ЗАВЕРШЕНИЕМ ПЕРВОГО\nДНЯ СТАЖИРОВКИ';

            const title = this.add.text(
                0,dinamycY,
                titleText,
                {
                    font: "20px Unbounded, Arial",
                    color: '#4d4d4d',
                    fixedWidth: textWidth,
                    wordWrap: {width: textWidth, useAdvancedWrap: true},
                    lineSpacing: 1.3
                }
            )

            dinamycY += gap + title.height;

            const textContent = 'Спасибо, что принял участие в игре «Однажды в офисе». Мы сейчас работаем над улучшением проекта: когда появятся новые уровни, мы расскажем тебе об этом. Возвращайся в игру, чтобы получить новые призы. \n' +
                '\nА пока —  переходи в каталог стажировок и выбирай подходящую именно тебе. Сделай первый шаг к карьере мечты!'

            const text = this.add.text(
                0, dinamycY,
                textContent,
                {
                    font: "16px Inter, Arial",
                    color: '#4d4d4d',
                    fixedWidth: textWidth,
                    wordWrap: {width: textWidth, useAdvancedWrap: true},
                    lineSpacing: 1.3
                }
            )

            dinamycY += gap + text.height;

            const do_request_button = this.add.image(0, dinamycY, 'do_request_button').setOrigin(0, 0);
            do_request_button.setInteractive({cursor: 'pointer'});

            dinamycY += do_request_button.height + 10

            vk = this.add.image(0, dinamycY, 'vk').setOrigin(0, 0);
            vk.setInteractive({cursor: 'pointer'});

            telegram = this.add.image(vk.width + 15, dinamycY, 'telegram').setOrigin(0, 0);
            telegram.setInteractive({cursor: 'pointer'});

            share = this.add.image(vk.width + 15 + telegram.width + 15, dinamycY, 'share').setOrigin(0, 0);
            share.setInteractive({cursor: 'pointer'});

            monitor.add(title);
            monitor.add(text);
            monitor.add(vk)
            monitor.add(telegram)
            monitor.add(share);
            monitor.add(do_request_button);

            hovers = [do_request_button, share, vk, telegram,]
        } else {
            const logo = this.add.image(0,dinamycY,'logo').setOrigin(0,0);

            dinamycY += gap+logo.height

            const textContent = 'Мы даем возможность попробовать свои силы на реальном рынке труда. Участников ждут лайфхаки, которые помогут подготовиться ко всем этапам трудоустройства. Лучшие смогут получить приглашение на стажировку от партнеров.'
            const text = this.add.text(
                0, dinamycY,
                textContent,
                {
                    font: "16px Inter, Arial",
                    color: '#4d4d4d',
                    fixedWidth: textWidth,
                    wordWrap: {width: textWidth, useAdvancedWrap: true},
                    lineSpacing: 1.3
                }
            )

            dinamycY += gap+gap+text.height

            vk = this.add.image(0, dinamycY, 'vk').setOrigin(0, 0);
            vk.setInteractive({cursor: 'pointer'});

            telegram = this.add.image(vk.width + 15, dinamycY, 'telegram').setOrigin(0, 0);
            telegram.setInteractive({cursor: 'pointer'});

            share = this.add.image(vk.width + 15 + telegram.width + 15, dinamycY, 'share').setOrigin(0, 0);
            share.setInteractive({cursor: 'pointer'});



            monitor.add(logo)
            monitor.add(vk)
            monitor.add(telegram)
            monitor.add(share)
            monitor.add(text)

            hovers = [vk, telegram, share,];
        }



        hovers.forEach(item=>{
            item.setDepth(2)
            const hover = this.add.image(item.x, item.y, `${item.texture.key}_hover`).setAlpha(0).setOrigin(0,0);
            hover.setInteractive({cursor: 'pointer'});
            let link = shareLink;
            hover.on('pointerdown', ()=> {
                switch (item.texture.key) {
                    case 'telegram':
                        link = `tg://msg_url?text=${shareText}&url=${shareLink}`
                        break;
                    case 'vk':
                        link = `https://vk.com/share.php?comment=${shareText}&url=${shareLink}`
                        break;
                    case 'do_request_button':
                        link = `https://rsv.ru/internships/`
                        break;
                }
                if (item.texture.key !== 'share') {
                    window.open(link, '_blank');
                } else {
                    const tempInput = document.createElement("input");
                    tempInput.value = link;
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    document.execCommand("copy");
                    document.body.removeChild(tempInput);
                    this.copied = this.add.sprite(718,-109, 'copied').setOrigin(0,0).setAlpha(0)
                    this.tweens.add({
                        targets: this.copied,
                        y: 116,
                        alpha: 1,
                        duration: 800,
                        ease: 'Linear'
                    });

                    setTimeout(()=>{
                        this.copied.destroy();
                    }, 2500)
                }

            })
            if (!this.sys.game.device.os.android && !this.sys.game.device.os.iOS) {
                item.on('pointerover', () => {
                    item.setAlpha(0);
                    hover.setAlpha(1);
                });
            }
            item.on('pointerdown', ()=> {
                switch (item.texture.key) {
                    case 'telegram':
                        link = `tg://msg_url?text=${shareText}&url=${shareLink}`
                        break;
                    case 'vk':
                        link = `https://vk.com/share.php?comment=${shareText}&url=${shareLink}`
                        break;
                    case 'do_request_button':
                        link = `https://rsv.ru/internships/`
                        break;
                }
                if (item.texture.key !== 'share') {
                    window.open(link, '_blank');
                } else {
                    const tempInput = document.createElement("input");
                    tempInput.value = link;
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    document.execCommand("copy");
                    document.body.removeChild(tempInput);
                    this.copied = this.add.sprite(718,-109, 'copied').setOrigin(0,0).setAlpha(0)
                    this.tweens.add({
                        targets: this.copied,
                        y: 116,
                        alpha: 1,
                        duration: 800,
                        ease: 'Linear'
                    });

                    setTimeout(()=>{
                        this.copied.destroy();
                    }, 2500)
                }

            })
            hover.on('pointerout', () => {
                item.setAlpha(1);
                hover.setAlpha(0);
            });
            monitor.add(hover)
        })

        monitor.add(statisticScreen);

        let statusOriginY = 122;
        const statuses = store.getters.getStatuses;

        for (let status in statuses) {
            let statusName = store.getters.getStatusName(status, statuses[status].count);
            const statusText = this.add.text(textWidth+128, statusOriginY, statusName.replace(/\n/g, " "), {font: '18px unbounded-400, Arial'});

            dinamycY += gap + statusText.height;

            monitor.add(statusText);

            statusOriginY+=84;
        }

        if (store.getters.getNeedFinal === true && !store.getters.getPrize) {
            this.prize = this.add.sprite(this.scale.width/2, this.scale.height - 232, 'prize').setDepth(3);

            this.anims.create({
                key: 'prizeAnimation',
                frames: this.anims.generateFrameNumbers('prize', {start: 0, end: 2}),
                frameRate: 4,
                repeat: -1
            });

            this.prize.play('prizeAnimation');

            this.prize.setInteractive({cursor: 'pointer'})

            this.prize.on('pointerdown', () => {
                this.prize.destroy(true);
                if (this.scale.isFullscreen)
                {
                    this.scale.stopFullscreen();
                }
                router.push({path: '/prize/'});
            })
        }

        this.sceneUI.addOpenSpace();
        this.sceneUI.addRestart();
    }
}